/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Group from 'vux/src/components/group';
import Cell from 'vux/src/components/cell';
import Previewer from 'vux/src/components/previewer';
import XButton from 'vux/src/components/x-button';
import Confirm from 'vux/src/components/confirm';
import TransferDom from 'vux/src/directives/transfer-dom';
import lookNode from '@/components/Approve/lookNode';
export default {
  name: 'ChangeShiftDetail',
  directives: {
    TransferDom: TransferDom
  },
  components: {
    Group: Group,
    Cell: Cell,
    XButton: XButton,
    Confirm: Confirm,
    Previewer: Previewer,
    lookNode: lookNode
  },
  data: function data() {
    return {
      applyMan: this.$utils.Store.getItem('userLname'),
      img: [{
        msrc: 'http://ww1.sinaimg.cn/thumbnail/663d3650gy1fplwu9ze86j20m80b40t2.jpg',
        src: 'http://ww1.sinaimg.cn/large/663d3650gy1fplwu9ze86j20m80b40t2.jpg'
      }, {
        msrc: 'http://ww1.sinaimg.cn/thumbnail/663d3650gy1fplwvqwuoaj20xc0p0t9s.jpg',
        src: 'http://ww1.sinaimg.cn/large/663d3650gy1fplwvqwuoaj20xc0p0t9s.jpg'
      }, {
        msrc: 'http://ww1.sinaimg.cn/thumbnail/663d3650gy1fplwwcynw2j20p00b4js9.jpg',
        src: 'http://ww1.sinaimg.cn/large/663d3650gy1fplwwcynw2j20p00b4js9.jpg'
      }],
      imgList: [],
      options: {
        getThumbBoundsFn: function getThumbBoundsFn(index) {
          var thumbnail = document.querySelectorAll('.previewer-demo-img')[index];
          var pageYScroll = window.scrollY || document.documentElement.scrollTop;
          var rect = thumbnail.getBoundingClientRect();
          return {
            x: rect.left,
            y: rect.top + pageYScroll,
            w: rect.width
          };
        }
      },
      showRepeal: false,
      //是否显示撤销弹窗
      showOperate: false,
      //是否显示审批弹窗
      xh: '',
      //申请序号
      type: '',
      //申请类型 0 请假；1 出差；2 外出；3 加班；4 调班；5 补出勤; 6 合同
      genre: 0,
      //类型  1：申请的  2：审批的  3：抄送的
      result: 0,
      //审批结果  1：同意  2：拒绝
      checkId: '',
      //审批ID
      reason: '',
      //审批意见
      // -----lwt的变量
      businessEntity: {},
      //人员信息---
      auditEntity: {},
      //节点信息
      qaList: [],
      //审批节点
      makeCopys: [],
      //抄送节点
      currentLevel: 0,
      //当前到达节点
      createTime: '',
      //申请审批时间
      completeTime: '',
      //结束审批时间
      applicant: '',
      //申请人编号
      applyPersonVo: {},
      //申请人
      status: 0,
      //状态
      userSerial: this.$utils.Store.getItem('userSerial'),
      isOp: false //可不可以审批

    };
  },
  computed: {
    isRepeal: function isRepeal() {
      /*是否显示撤销按钮 */
      if (this.genre == 1 && (this.status == 0 || this.status == 1)) {
        return true;
      } else {
        return false;
      }
    },
    isOperate: function isOperate() {
      /* 是否显示审批按钮*/
      if (this.genre == 2 && (this.status == 0 || this.status == 1) && this.isOp) {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    /**
     *  function获取调班详情接口
     *  @wzx
     *  @2018-12-24
     **/
    getChangeShiftDetail: function getChangeShiftDetail() {
      var _this = this;

      this.$utils.Tools.loadShow();
      this.$api.Appr.queryAuditInfo({
        businessNo: this.xh,
        userSerial: this.$utils.Store.getItem('userSerial')
      }).then(function (response) {
        _this.$utils.Tools.loadHide();

        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== _this.$code.success) {
          _this.$utils.Tools.toastShow(msg);
        } else {
          // this.apply = data.midData;
          // this.approver = data.botData;
          // this.checkId = data.midData.checkId;
          // this.xh = data.midData.xh;
          _this.businessEntity = data.businessEntity;
          _this.businessEntity.tbrq = _this.businessEntity.tbrq.slice(0, 10);
          _this.auditEntity = data.auditEntity;
          _this.applyPersonVo = _this.auditEntity.applyPersonVo;
          _this.qaList = _this.auditEntity.qaList;
          _this.applicant = _this.applyPersonVo.applicant;
          _this.currentLevel = _this.auditEntity.currentLevel;
          _this.createTime = _this.auditEntity.createTime;
          _this.completeTime = _this.auditEntity.completeTime;
          _this.status = _this.auditEntity.status;

          if (data.makeCopys) {
            _this.auditEntity.makeCopys = data.makeCopys;
          }

          _this.qaList.some(function (item) {
            if (_this.currentLevel == item.level) {
              _this.checkId = item.workflowRecordId;
              item.persionList.some(function (sub) {
                if (sub.userSerial == _this.userSerial && (sub.status == -1 || sub.status == 0)) {
                  _this.isOp = true;
                }
              });
            }
          });

          for (var i = 0; i < _this.businessEntity.imgPath.length; i++) {
            _this.imgList.push({
              src: _this.businessEntity.imgPath[i],
              msrc: _this.businessEntity.imgPath[i]
            });
          }
        }
      }).catch(function () {
        _this.$utils.Tools.httpError();
      });
    },

    /**
     *  function点击撤销申请按钮
     *  @wzx
     *  @2018-12-26
     **/
    repealClick: function repealClick() {
      this.showRepeal = true;
    },

    /**
     *  function点击审批同意按钮
     *  @wzx
     *  @2018-12-26
     **/
    agreeClick: function agreeClick() {
      this.result = 2;
      this.showOperate = true; // this.reason = '';
    },

    /**
     *  function点击审批拒绝按钮
     *  @wzx
     *  @2018-12-26
     **/
    refuseClick: function refuseClick() {
      this.result = 3;
      this.showOperate = true; // this.reason = '';
    },

    /**
     *  function审批接口
     *  @wzx
     *  @2018-12-26
     **/
    onOperate: function onOperate() {
      var _this2 = this;

      this.$utils.Tools.loadShow();
      this.$api.Appr.audit({
        // checkId: this.checkId,
        workflowRecordId: this.checkId,
        auditStatus: this.result,
        userSerial: this.$utils.Store.getItem('userSerial'),
        content: this.reason
      }).then(function (response) {
        _this2.$utils.Tools.loadHide();

        var code = response.code,
            msg = response.msg;

        if (+code !== _this2.$code.success) {
          _this2.$utils.Tools.toastShow(msg);
        } else {
          _this2.$utils.Tools.toastShow(msg);

          setTimeout(function () {
            window.location.reload(); // this.$router.go(0);
          }, 1000);
        }
      }).catch(function () {
        _this2.$utils.Tools.httpError();
      });
    },
    hideReason: function hideReason() {},
    onCancel: function onCancel() {},

    /**
     *  function删除请假申请接口
     *  @wzx
     *  @2018-12-25
     **/
    onConfirm: function onConfirm() {
      var _this3 = this;

      this.$utils.Tools.loadShow();
      var params = {
        userSerial: this.$utils.Store.getItem('userSerial'),
        xh: this.xh
      };
      this.$api.Appr.undo(params).then(function (response) {
        _this3.$utils.Tools.loadHide();

        var code = response.code,
            msg = response.msg;

        if (+code !== _this3.$code.success) {
          _this3.$utils.Tools.toastShow(msg);
        } else {
          setTimeout(function () {
            window.location.reload(); // this.$router.go(0);
          }, 1000);
        }
      }).catch(function () {
        _this3.$utils.Tools.httpError();
      });
    },
    onHide: function onHide() {},
    onShow: function onShow() {},
    logIndexChange: function logIndexChange() {},
    show: function show(index) {
      this.$refs.previewer.show(index);
    },
    pushHistory: function pushHistory() {
      var state = {
        title: 'title',
        url: '#'
      };
      window.history.pushState(state, state.title, state.url);
    }
  },
  created: function created() {
    var strs = [];

    if (this.$utils.Store.getItem('state')) {
      strs = decodeURI(this.$utils.Store.getItem('state')).split(',');
      this.xh = strs[0];
      this.genre = strs[1];
    } else {
      this.$utils.Store.setItem('state', this.$route.params.state);
      strs = decodeURI(this.$route.params.state).split(',');
      this.xh = strs[0];
      this.genre = strs[1];
    }

    this.getChangeShiftDetail();
  },
  mounted: function mounted() {
    if (this.$route.params.notification) {
      this.pushHistory();
    }
  },
  destroyed: function destroyed() {
    this.$utils.Store.removeItem('state');
  }
};